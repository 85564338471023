/* eslint-disable react/forbid-component-props */
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  BRAND_WOWCHER,
  DEFAULT_MAIN_DEAL_IMG_HEIGHT,
  DEFAULT_MAIN_DEAL_IMG_WIDTH,
} from '../../../config/setup/setup';
import { GO_TO_DEAL, VIEW } from '../../../config/text/text';
import { initializeTracking } from '../../../helpers/analytics';
import {
  useSocialCues,
  getUrlFromDeal,
  hidePrice,
  showPostage,
  useDealImpresssion,
  isClosedDeal,
} from '../../../helpers/deals';
import {
  getDealHeadline,
  getDealTitle,
  getVipExclusiveTag,
} from '../../../helpers/vipHub';
import ThemeContext from '../../../providers/ThemeProvider';
import Clamp from '../../_generic/utils/Clamp';
import DealPriceSplat from '../shared-components/DealPriceSplat';
import DealSocialCueTop from '../shared-components/DealSocialCueTop';
import DealSocialCuesBottom from '../shared-components/DealSocialCuesBottom';
import PostageAndPackagingText from '../shared-components/PostageAndPackagingText';
import VipExclusiveTag from '../shared-components/VipExclusiveTag';
import CarouselBlock from '../shared-components/carousel/CarouselBlock';
import EmptyDeal from '../shared-components/emptyDeal';

const WishlistIcon = dynamic(
  () => import('../shared-components/WishlistIcon'),
  {
    ssr: false,
  },
);

const CategoryMainDeal = ({ deal, extraclasses }) => {
  const theme = useContext(ThemeContext);
  const [
    ssr,
    isVipUser,
    mainDeal,
    trackingDeals,
    secondaryDealsArray,
  ] = useSelector((state) => [
    state.deals.ssr,
    state?.user?.userprofile?.vipUser,
    state?.deals?.mainDeal,
    state?.tracking?.trackingDeals,
    state?.deals?.secondaryDealsArray,
  ]);
  const router = useRouter();
  const site = process.env.NEXT_PUBLIC_BRAND || BRAND_WOWCHER;
  const isClosed = isClosedDeal(deal, router.query, router.pathname);
  const socialCuesData = useSocialCues(
    deal?.id,
    isClosed || deal?.totalRemaining <= 0,
  );

  const location = useSelector((state) => state.locations.location.shortName);
  const componentRef = useRef(null);
  useDealImpresssion({ componentRef, dealId: deal.id, location });

  if (!deal || Object.keys(deal).length === 0) return <EmptyDeal />;

  const dealTitle = getDealTitle({ deal, isVipUser });
  const dealHeadline = getDealHeadline({ deal, isVipUser });
  const showVipExclusiveTag = getVipExclusiveTag({ deal, isVipUser });

  const title = dealTitle ? hidePrice(dealTitle, ssr) : '';
  const headline = dealHeadline ? hidePrice(dealHeadline, ssr) : '';
  const hasPostage = showPostage(deal, false, false);
  const dealUrlPath = getUrlFromDeal({
    deal,
    originPath: router.asPath,
  });

  const setScrollDataOnClick = () => {
    initializeTracking(deal, [
      mainDeal,
      ...secondaryDealsArray,
      ...trackingDeals,
    ]);
  };

  const reviewScore = deal.reviewSummary?.average || null;
  const reviewCount = deal.reviewSummary?.total || null;

  return (
    <>
      <div
        className={`card cat-main-deal deal_item ${extraclasses}`}
        data-review-count={reviewCount}
        data-review-score={reviewScore}
        onClick={setScrollDataOnClick}
        ref={componentRef}
      >
        <div className="cat-main-deal__header">
          <Link as={dealUrlPath} href="/deal/[...slug]">
            <a
              aria-label={GO_TO_DEAL.replace('##DEAL##', deal.headline)}
              className="cat-main-deal__header-text"
            >
              <Clamp lines={hasPostage ? 3 : 4}>
                <h3 className="cat-main-deal__title">{headline}</h3>
                <p className="cat-main-deal__subtitle">{title}</p>
              </Clamp>
              <PostageAndPackagingText
                className="mobile-invert display-mobile"
                deal={deal}
                show={hasPostage}
              />
            </a>
          </Link>
          <div className="cat-main-deal__header-buttons">
            <Link as={dealUrlPath} href="/deal/[...slug]">
              <a
                aria-label={GO_TO_DEAL.replace('##DEAL##', deal.headline)}
                className={`cat-main-deal__view-button ${
                  site !== BRAND_WOWCHER && 'lowercase_text'
                }`}
              >
                {VIEW}
              </a>
            </Link>
          </div>
        </div>
        <div className="cat-main-deal__image">
          <DealSocialCueTop
            socialCueClaim={deal?.socialCueClaim}
            socialCuesAvailability={deal.totalRemaining || 0}
            socialCuesBought={deal.totalBought || 0}
            socialCuesData={socialCuesData}
          />
          {showVipExclusiveTag && <VipExclusiveTag />}
          <DealPriceSplat
            className="top-right"
            deal={deal}
            forcePrice={false}
            visible={!ssr}
          />
          <WishlistIcon dealId={deal.id} left />
          <CarouselBlock
            deal={deal}
            height={DEFAULT_MAIN_DEAL_IMG_HEIGHT}
            lazy={false}
            showImageOverlays
            showScrim
            width={DEFAULT_MAIN_DEAL_IMG_WIDTH}
          />

          <DealSocialCuesBottom
            deal={deal}
            dealTitle={dealTitle}
            discount={false}
            hideCue
            show={hasPostage}
            socialCuesData={deal}
            socialCuesExtra={socialCuesData}
          />
        </div>
      </div>
      <style jsx>{`
        .cat-main-deal {
          margin: 0;
          padding: 0;
          text-align: left;
          color: inherit;
          text-decoration: none;
          border-radius: 10px;
          overflow: hidden;
          transition: color 0.15s ease, border-color 0.15s ease;
          position: relative;
          background-color: ${theme.colors.dealbackgroundcolor};
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
          display: flex;
          flex-direction: column;
        }
        .cat-main-deal__header {
          width: 100%;
          display: flex;
          align-items: center;
          min-height: 92px;
        }
        .cat-main-deal__header-text {
          flex-grow: 1;
          color: inherit;
          text-decoration: none;
          padding: 0 8px;
        }
        .cat-main-deal__title,
        .cat-main-deal__subtitle {
          margin: 0;
        }
        .cat-main-deal__title {
          font-size: 16px;
        }
        .cat-main-deal__header-buttons {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-right: 8px;
        }
        .cat-main-deal__view-button {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-size: 18px;
          font-weight: bold;
          background-color: ${theme.colors.dealviewbutton};
          text-transform: uppercase;
          padding: 0.7rem 1rem;
          border-radius: 2rem;
          border: none;
          outline: none;
          text-decoration: none;
          width: 96px;
        }
        .lowercase_text {
          text-transform: lowercase;
        }
        .cat-main-deal__bought-count {
          display: none;
          margin: 0;
        }
        .cat-main-deal__image {
          position: relative;
        }
        :global(.cat-main-deal .thumbs) {
          position: absolute;
          bottom: 38px;
          left: 0;
          width: 100%;
        }
        :global(.cat-main-deal .control-dots) {
          position: absolute;
          bottom: 0;
          margin: 20px 20px 20px 0;
          margin: 10px 0;
          pointer-events: none;
        }
        :global(.cat-main-deal .carousel__image--single) {
          width: 100%;
        }

        @media (max-width: ${theme.breakpoints.smDown}) {
          .cat-main-deal {
            border-radius: 0;
          }
          .cat-main-deal__header {
            position: absolute;
            bottom: 0;
            z-index: 2;
            color: white;
            padding-bottom: 36px;
            min-height: 0;
            max-width: 78%;
          }
          .cat-main-deal__header-text {
            padding-top: 0;
            padding-bottom: 0;
          }
          .cat-main-deal__header-buttons {
            display: none;
          }
          .cat-main-deal__subtitle {
            display: none;
          }
          :global(.cat-main-deal .bought-count) {
            position: absolute;
            bottom: 10px;
            background-color: transparent !important;
            color: white !important;
            padding: 0;
            margin: 0;
          }
          :global(.cat-main-deal .thumbs) {
            display: none;
          }
          :global(.cat-main-deal .control-dots) {
            display: none;
          }
        }
      `}</style>
    </>
  );
};

CategoryMainDeal.propTypes = {
  deal: PropTypes.object,
  extraclasses: PropTypes.string,
};

CategoryMainDeal.defaultProps = {
  deal: null,
  extraclasses: '',
};

export default CategoryMainDeal;
