import axios from '../../components/_generic/axiosSplunk/axiosSplunk';
import { FILTERS } from '../../config/constants/action-types';
import { LINK_FILTER_API, DESKTOP_PAGE_SIZE } from '../../config/links/links';
import { getAppPlatform } from '../../helpers/device';

export const setEmptyFilters = () => (dispatch) => {
  return dispatch({
    type: FILTERS.EMPTY_FILTERS,
  });
};

export const setFilters = (mainDeal, deals, ssr) => (dispatch) => {
  return dispatch({
    deals,
    mainDeal,
    ssr,
    type: FILTERS.SET_FILTERS,
  });
};

export const resetFilters = () => (dispatch) => {
  return dispatch({
    type: FILTERS.RESET_FILTERS,
  });
};

export const setFiltersExtra = (newdeals) => (dispatch) => {
  return dispatch({
    newdeals,
    type: FILTERS.SET_EXTRA_FILTERS,
  });
};

export const setNoMoreFilters = () => (dispatch) => {
  return dispatch({
    type: FILTERS.SET_NO_MORE_FILTERS,
  });
};

export const setURLFilterPath = (url) => (dispatch) => {
  return dispatch({
    type: FILTERS.SET_URL_PATH,
    url,
  });
};

const getFilterQuery = (orderBy, minPrice, maxPrice, pageNumber = null) => {
  const query = new URLSearchParams({ pageSize: DESKTOP_PAGE_SIZE });

  if (orderBy && orderBy.length) {
    if (orderBy === 'pricelow') {
      query.append('orderBy', 'price');
      query.append('order', 'asc');
    } else {
      query.append('orderBy', orderBy);
    }
  }

  if (minPrice && minPrice.length) {
    query.append('minPrice', minPrice);
  }

  if (maxPrice && maxPrice.length) {
    query.append('maxPrice', maxPrice);
  }

  if (pageNumber) {
    query.append('page', pageNumber);
  }

  return query.toString();
};

export const setFilterPath = (shortName, facetedNavigation) => async (
  dispatch,
) => {
  if (!shortName || !facetedNavigation || !facetedNavigation.navigations) {
    dispatch(setURLFilterPath('')); // reset url filter path

    return;
  }
  const newItem = facetedNavigation.navigations.find(
    (item) => item.shortName && item.shortName === shortName,
  );

  if (!newItem || !newItem.url) {
    dispatch(setURLFilterPath('')); // reset url filter path
  }
  dispatch(setURLFilterPath(newItem.url));
};

const setURL = ({
  category,
  location,
  subcategory,
  orderBy,
  minPrice,
  maxPrice,
  pageNumber,
}) => {
  let url = [LINK_FILTER_API, location, category, subcategory]
    .filter((index) => index)
    .join('/');

  const query = getFilterQuery(orderBy, minPrice, maxPrice, pageNumber);

  if (query && query.length) {
    url = `${url}?${query}`;
  }

  return url;
};

export const getFilter = ({
  category,
  location,
  subcategory = '',
  orderBy = '',
  minPrice = '',
  maxPrice = '',
  ssr = false,
  pageNumber = null,
  secondaryDealsArray = null,
}) => async (dispatch) => {
  try {
    const url = setURL({
      category,
      location,
      maxPrice,
      minPrice,
      orderBy,
      pageNumber,
      subcategory,
    });

    const data = await axios(url, {
      headers: {
        'app-platform': getAppPlatform(),
        brand: process.env.NEXT_PUBLIC_BRAND || 'wowcher',
        'country-code': process.env.NEXT_PUBLIC_COUNTRY_CODE || 'gb',
        webapp: true,
      },
      method: 'GET',
      withCredentials: true,
    });

    if (!data || !data.data || !data.data.deals || !data.data.deals.length) {
      dispatch(setEmptyFilters());

      return {
        deals: [],
        mainDeal: {},
      };
    }

    if (secondaryDealsArray && secondaryDealsArray.length > 0) {
      // remove the secondary deals from the filter deals
      secondaryDealsArray.forEach((item) => {
        if (data.data.deals[0] && data.data.deals[0].id === item.id) {
          data.data.deals.shift();
        }
      });
    }
    dispatch(setFilters(data.data.mainDeal || {}, data.data.deals || [], ssr));

    return {
      deals: data.data.deals || [],
      mainDeal: data.data.mainDeal || {},
    };
  } catch {
    // TODO: Show the error if needed
    return false;
  }
};

export const getFilterByPage = ({
  category,
  location,
  subcategory = '',
  orderBy = '',
  minPrice = '',
  maxPrice = '',
  pageNumber = null,
}) => async (dispatch) => {
  try {
    if (!category || !location) {
      return;
    }
    const url = setURL({
      category,
      location,
      maxPrice,
      minPrice,
      orderBy,
      pageNumber,
      subcategory,
    });

    const data = await axios(url, {
      headers: {
        'app-platform': getAppPlatform(),
        brand: process.env.NEXT_PUBLIC_BRAND || 'wowcher',
        'country-code': process.env.NEXT_PUBLIC_COUNTRY_CODE || 'gb',
        webapp: true,
      },
      method: 'GET',
      withCredentials: true,
    });

    const dealsTotal = data.data.deals
      ? data.data.deals.map((item) =>
          Object.assign({}, item, { page: pageNumber }),
        )
      : [];

    if (!dealsTotal || !dealsTotal.length) {
      dispatch(setNoMoreFilters());
    }

    dispatch(setFiltersExtra(dealsTotal || []));
  } catch {
    // TODO: Show the error if needed
  }
};
